<template>
    <div class="wrapper moneyBack">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg">
                        <table>
                            <tbody>
                                <tr>
                                    <td style="width:.8rem;border-right:1px solid #ebeef5!important">
                                        序号
                                    </td>
                                    <td style="width:1.5rem;border-right:1px solid #ebeef5!important">
                                        品种
                                    </td>
                                    <td style="width:1.5rem;">
                                        项目
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in moneyBackData">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.totalInfo.date>0">
                            {{ item.totalInfo.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.totalInfo.date==0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody class="border6">
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <th rowspan="16" class="custom-th-even" style="width:.8rem;">
                                        总计
                                    </th>
                                    <th rowspan="6" style="width:1.5rem;border-right: 1px solid #ebeef5 !important;">
                                        货款回收
                                    </th>
                                    <th style="width:1.5rem;">
                                        贷款回收率（%）
                                    </th>
                                </tr>
                                <tr>
                                    <th>总计（万元）</th>
                                </tr>
                                <tr>
                                    <th>现金（万元）</th>
                                </tr>
                                <tr>
                                    <th>承兑（万元）</th>
                                </tr>
                                <tr>
                                    <th>抵账（万元）</th>
                                </tr>
                                <tr>
                                    <th>其他（万元）</th>
                                </tr>
                                <tr>
                                    <th rowspan="3">
                                        应收账款余额
                                    </th>
                                    <th>减少（万元）</th>
                                </tr>
                                <tr>
                                    <th>期初（万元）</th>
                                </tr>
                                <tr>
                                    <th>期末（万元）</th>
                                </tr>
                                <tr>
                                    <th rowspan="2">
                                        预收账款
                                    </th>
                                    <th>期初（万元）</th>
                                </tr>
                                <tr>
                                    <th>期末（万元）</th>
                                </tr>
                                <tr>
                                    <th rowspan="2">
                                        应收账款
                                    </th>
                                    <th>期初（万元）</th>
                                </tr>
                                <tr>
                                    <th>期末（万元）</th>
                                </tr>
                                <tr>
                                    <th class="td-right">
                                        账龄：1年内
                                    </th>
                                    <th>期末（万元）</th>
                                </tr>
                                <tr>
                                    <th class="td-right">
                                        2年内
                                    </th>
                                    <th>期末（万元）</th>
                                </tr>
                                <tr>
                                    <th class="td-right">
                                        3年内
                                    </th>
                                    <th>期末（万元）</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in moneyBackData">
                        <td :key="index">
                            <table v-if="isDrill">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[0],-1,0,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[0].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[1],-2,1,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[1].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[2],-3,2,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[2].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[3],-4,3,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[3].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[4],-5,4,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[4].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalDetailInfos[5],-6,5,-1,2)">
                                            <a href="javascript:;">{{ item.totalDetailInfos[5].value }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,4,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.pay_balance_reduce }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,5,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.pay_balance_begin }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,6,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.pay_balance_end }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,7,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.advance_begin }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,8,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.advance_end }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,9,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.pay_begin }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,10,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.pay_end }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,11,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.accountage_0 }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,12,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.accountage_1 }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,13,-1,-1,1)">
                                            <a href="javascript:;">{{ item.totalInfo.accountage_2 }}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-else>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[0].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[1].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[2].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[3].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[4].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalDetailInfos[5].value }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.pay_balance_reduce }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.pay_balance_begin }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.pay_balance_end }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.advance_begin }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.advance_end }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.pay_begin }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.pay_end }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.accountage_0 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.accountage_1 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ item.totalInfo.accountage_2 }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
            </tbody>
            <tbody>
                <template v-if="moneyBackProjectData.length > 0">
                    <tr v-for="(item, index) in moneyBackProjectData" :key="index" :class="{'border6': index<moneyBackProjectData.length-1}">
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <th rowspan="20" :class="{'custom-th-odd':index%2===0,'custom-th-even':index%2===1}" style="width:.8rem;">
                                            {{ item.label }}<br>回款情况
                                        </th>
                                        <th
                                            rowspan="4"
                                            style="width:1.5rem;border-right: 1px solid #ebeef5 !important;"
                                        >
                                            {{ item.label }}
                                        </th>
                                        <th
                                            style="width:1.5rem;"
                                        >
                                            商混销量（万方）
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>商混售价（元/方）</th>
                                    </tr>
                                    <tr>
                                        <th>砂浆销量（万吨）</th>
                                    </tr>
                                    <tr>
                                        <th>砂浆售价（元/吨）</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="6">
                                            货款回收
                                        </th>
                                        <th>
                                            贷款回收率（%）
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>总计（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>现金（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>承兑（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>抵账（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>其他（万元）</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="3">
                                            应收账款余额
                                        </th>
                                        <th>减少（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>期初（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>期末（万元）</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2">
                                            预收账款
                                        </th>
                                        <th>期初（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>期末（万元）</th>
                                    </tr>
                                    <tr>
                                        <th rowspan="2">
                                            应收账款
                                        </th>
                                        <th>期初（万元）</th>
                                    </tr>
                                    <tr>
                                        <th>期末（万元）</th>
                                    </tr>
                                    <tr>
                                        <th class="td-right">
                                            账龄：1年内
                                        </th>
                                        <th>期末(万元)</th>
                                    </tr>
                                    <tr>
                                        <th class="td-right">
                                            2年内
                                        </th>
                                        <th>期末(万元)</th>
                                    </tr>
                                    <tr>
                                        <th class="td-right">
                                            3年内
                                        </th>
                                        <th>期末(万元)</th>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(k, i) in item.projectReports">
                            <td :key="i">
                                <table v-if="isDrill">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,0,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.sales_concrete }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,1,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.price_concrete }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,2,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.sales_mortar }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,3,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.price_mortar }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[0],-1,0,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[0].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[1],-2,1,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[1].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[2],-3,2,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[2].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[3],-4,3,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[3].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[4],-5,4,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[4].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectDetailInfos[5],-6,5,index,4)">
                                                <a href="javascript:;">{{ k.projectDetailInfos[5].value }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,4,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.pay_balance_reduce }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,5,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.pay_balance_begin }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,6,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.pay_balance_end }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,7,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.advance_begin }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,8,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.advance_end }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,9,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.pay_begin }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,10,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.pay_end }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,11,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.accountage_0 }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,12,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.accountage_1 }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k.projectInfo,13,-1,index,3)">
                                                <a href="javascript:;">{{ k.projectInfo.accountage_2 }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-else>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.sales_concrete }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.price_concrete }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.sales_mortar }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.price_mortar }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[0].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[1].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[2].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[3].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[4].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectDetailInfos[5].value }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.pay_balance_reduce }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.pay_balance_begin }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.pay_balance_end }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.advance_begin }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.advance_end }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.pay_begin }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.pay_end }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.accountage_0 }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.accountage_1 }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.projectInfo.accountage_2 }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 回款情况
    name: 'money-back',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
        reportData: {
            type: [Object],
        },
    },
    data() {
        return {
            // 回款情况数据集合 
            moneyBackData: [
                {
                    totalInfo: {},
                    totalDetailInfos: [
                        { value: null },
                    ],
                },
            ],
            // 项目回款情况数据集合 
            moneyBackProjectData: {},
            isDrill: true,
        };
    },
    watch: {
        queryStr: function () {
            this.gitmoneyBackData();
        },
    },
    computed: {},
    methods: {
        gitmoneyBackData() {
            this.$axios
                .get('/interfaceApi/report/payback/report/total/' + this.queryStr)
                .then(res => {
                    this.moneyBackData = res;
                    // 获取项目回款情况数据集合 
                    this.gitmoneyBackProjectData();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        gitmoneyBackProjectData() {
            this.$axios
                .get('/interfaceApi/report/payback/report/project/' + this.queryStr)
                .then(res => {
                    this.moneyBackProjectData = res;
                    // this.loadObj.close();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        // field 字段 name 货款类型 project 工程类型 type 数据类型
        /* eslint-disable */
        showDataDrills(rowData, field, name, project, type ) {
            let parseField = 0;
            let parsetText = null;
            let showType = null;
            switch (field) {
                case -1:
                    parseField = field;
                    parsetText = '贷款回收率(%)';
                    showType = 2;
                    break;
                case -2:
                    parseField = field;
                    parsetText = '总计(万元)';
                    break;
                case -3:
                    parseField = field;
                    parsetText = '现金(万元)';
                    break;
                case -4:
                    parseField = field;
                    parsetText = '承兑(万元)';
                    break;
                case -5:
                    parseField = field;
                    parsetText = '抵账(万元)';
                    break;
                case -6:
                    parseField = field;
                    parsetText = '其他(万元)';
                    break;
                case 0:
                    parseField = 0;
                    parsetText = '商混销量(万方)';
                    break;
                case 1:
                    parseField = 1;
                    parsetText = '商混售价(元/方)';
                    break;
                case 2:
                    parseField = 2;
                    parsetText = '砂浆销量(万吨)';
                    break;
                case 3:
                    parseField = 3;
                    parsetText = '砂浆售价(元/吨)';
                    break;
                case 4:
                    parseField = 4;
                    parsetText = '减少(万元)';
                    showType = 1;
                    break;
                case 5:
                    parseField = 5;
                    parsetText = '期初(万元)';
                    showType = 1;
                    break;
                case 6:
                    parseField = 6;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
                case 7:
                    parseField = 7;
                    parsetText = '期初(万元)';
                    showType = 1;
                    break;
                case 8:
                    parseField = 8;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
                case 9:
                    parseField = 9;
                    parsetText = '期初(万元)';
                    showType = 1;
                    break;
                case 10:
                    parseField = 10;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
                case 11:
                    parseField = 11;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
                case 12:
                    parseField = 12;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
                case 13:
                    parseField = 13;
                    parsetText = '期末(万元)';
                    showType = 1;
                    break;
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: parseField,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                type: type,
                name: name,
                project: project,
                firstPop: true,
                sourceReport: 'moneyBackTable',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {},
    mounted() {
        // 获取回款情况数据
        this.gitmoneyBackData();
        this.isDrillFn();
    },
};
</script>
<style lang="stylus">
.moneyBack
    table
        table
            width 100%
            height 100%
            border-collapse collapse
            table-layout fixed
            word-break break-all
            tr
                &:last-child
                    td
                        border none !important
                th
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    font-weight 400
                    &:nth-of-type(1)
                        border-right 1px solid #ebeef5 !important
                td
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    line-height initial !important
</style>